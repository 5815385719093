export const getFilenameFromUrl = (url: string) => {
  if (url === '') {
    return {
      name: '',
      extension: '',
    }
  }
  const { pathname } = new URL(url)
  const slashIndex = pathname.lastIndexOf('/')
  const fileName = pathname.substring(slashIndex + 1)
  const extension = fileName.split('.').pop()

  return {
    name: fileName,
    extension,
  }
}
